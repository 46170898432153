import { Routes } from '@angular/router';
import { Constant } from 'app/config/constants';
import { ErrorComponent } from './error.component';

export const errorRoute: Routes = [
  {
    path: 'error',
    component: ErrorComponent,
    data: {
      authorities: [],
      pageTitle: 'Error page!'
    }
  },
  {
    path: 'error/accessdenied',
    component: ErrorComponent,
    data: {
      authorities: [],
      pageTitle: 'Error page!',
      errorMessage: 'You are not authorized to access this page.'
    }
  },
  {
    path: Constant.ERROR_404_PATH,
    component: ErrorComponent,
    data: {
      authorities: [],
      pageTitle: 'Error page!',
      errorMessage: 'The page does not exist.'
    }
  },
  {
    path: '**',
    redirectTo: Constant.ERROR_404_PATH
  }
];

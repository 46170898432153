import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  ALL_SOFTWARES,
  COLOR_TITLE,
  Constant,
  FIELD_COMPONENT,
  MODULE_NAME,
  MODULE_NAME_NEED_PROJECT,
  NUMBER_LAYOUT,
  TYPE_LAYOUT,
  menuComponents
} from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { Common } from 'app/model/entity/common';
import { AnnouncementManagerComponent } from 'app/module/announcement-manager/announcement-manager.component';
import { BusInformationDisplayEditorComponent } from 'app/module/bus-information-display-editor/bus-information-display-editor.component';
import { DashboardComponent } from 'app/module/dashboard/dashboard.component';
import { DeliveryManagerComponent } from 'app/module/delivery-manager/delivery-manager.component';
import { DestinationSignEditorComponent } from 'app/module/destination-sign-editor/destination-sign-editor.component';
import { DeviceManagerComponent } from 'app/module/device-manager/device-manager.component';
import { DigitalSignageContentEditorComponent } from 'app/module/digital-signage-content-editor/digital-signage-content-editor.component';
import { EdsEditorComponent } from 'app/module/eds-editor/eds-editor.component';
import { ExternalContentManagerComponent } from 'app/module/external-content-manager/external-content-manager.component';
import { IndexWordEditorComponent } from 'app/module/index-word-editor/index-word-editor.component';
import { LcdLayoutEditorComponent } from 'app/module/lcd-layout-editor/lcd-layout-editor.component';
import { LedLayoutEditorComponent } from 'app/module/led-layout-editor/led-layout-editor.component';
import { MasterListEditorComponent } from 'app/module/master-list-editor/master-list-editor.component';
import { MediaManagerComponent } from 'app/module/media-manager/media-manager.component';
import { ProjectManagerComponent } from 'app/module/project-manager/project-manager.component';
import { RouteListEditorComponent } from 'app/module/route-list-editor/route-list-editor.component';
import { ScheduleMergeComponent } from 'app/module/schedule-merge/schedule-merge.component';
import { ScheduleOperationManagerComponent } from 'app/module/schedule-operation-manager/schedule-operation-manager.component';
import { SignageDisplayEditorComponent } from 'app/module/signage-display-editor/signage-display-editor.component';
import { SimpleSignageEditorComponent } from 'app/module/simple-signage-editor/simple-signage-editor.component';
import { StationDisplayEditorComponent } from 'app/module/station-display-editor/station-display-editor.component';
import { TicketEditorTabSpotComponent } from 'app/module/ticket-editor/ticket-editor-tab-spot/ticket-editor-tab-spot.component';
import { TicketEditorComponent } from 'app/module/ticket-editor/ticket-editor.component';
import { TicketManagerComponent } from 'app/module/ticket-manager/ticket-manager.component';
import { TimetableEditorComponent } from 'app/module/timetable-editor/timetable-editor.component';
import { TimetableOperationManagerComponent } from 'app/module/timetable-operation-manager/timetable-operation-manager.component';
import { UserManagerComponent } from 'app/module/user-manager/user-manager.component';
import { SaveMainStateAction, SaveUserInfoAction } from 'app/ngrx-component-state-management/component-state.action';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { LoginModalComponent } from 'app/shared/login/login.component';
import { AppState } from 'app/store/app.state';
import { JhiLanguageService } from 'ng-jhipster';
import { Helper } from '../helper';
import { ScheduleRegistrationComponent } from './../../module/schedule-registration/schedule-registration.component';

@Component({
  selector: 'app-layout2',
  templateUrl: './layout2.component.html',
  styleUrls: ['./layout2.component.scss']
})
export class Layout2Component implements OnInit {
  /**
   * entryComponents
   */
  entryComponents = [
    UserManagerComponent,
    ProjectManagerComponent,
    DeviceManagerComponent,
    IndexWordEditorComponent,
    ExternalContentManagerComponent,
    LcdLayoutEditorComponent,
    LedLayoutEditorComponent,
    MasterListEditorComponent,
    RouteListEditorComponent,
    TimetableEditorComponent,
    DestinationSignEditorComponent,
    SignageDisplayEditorComponent,
    BusInformationDisplayEditorComponent,
    StationDisplayEditorComponent,
    ScheduleRegistrationComponent,
    ScheduleMergeComponent,
    DeliveryManagerComponent,
    TicketEditorComponent,
    TicketEditorTabSpotComponent,
    MediaManagerComponent,
    DigitalSignageContentEditorComponent,
    SimpleSignageEditorComponent,
    TimetableOperationManagerComponent,
    ScheduleOperationManagerComponent,
    AnnouncementManagerComponent,
    TicketManagerComponent,
    DashboardComponent,
    LoginModalComponent,
    EdsEditorComponent
  ];

  @ViewChild('screen1') private screen1: ElementRef;
  @ViewChild('screen2') private screen2: ElementRef;
  @ViewChild('menuSoftware1') private menuSoftware1: ElementRef;
  @ViewChild('menuSoftware2') private menuSoftware2: ElementRef;
  menuComponents = menuComponents;

  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;
  /**
   * TYPE_LAYOUT
   */
  TYPE_LAYOUT = TYPE_LAYOUT;
  /**
   * NUMBER_LAYOUT
   */
  NUMBER_LAYOUT = NUMBER_LAYOUT;
  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;
  /**
   * COLOR_TITLE
   */
  COLOR_TITLE = COLOR_TITLE;
  /**
   * ALL_SOFTWARES
   */
  ALL_SOFTWARES = ALL_SOFTWARES;
  MODULE_NAME_NEED_PROJECT = MODULE_NAME_NEED_PROJECT;
  /**
   * typeLayout
   */
  typeLayout: TYPE_LAYOUT;

  /**
   * moduleName of layout 1
   */
  moduleName: string;
  /**
   * moduleName of layout 2
   */
  moduleName2: string;
  /**
   * colorTask of layout 1
   */
  colorTask: string;
  /**
   * colorTask of layout 2
   */
  colorTask2: string;
  /**
   * ElementRef moduleLayout1
   */
  @ViewChild('moduleLayout', {
    read: ViewContainerRef,
    static: true
  })
  moduleLayout;
  /**
   * ElementRef moduleLayout1
   */
  @ViewChild('moduleLayout_1', {
    read: ViewContainerRef,
    static: true
  })
  moduleLayout_1;
  /**
   * ElementRef moduleLayout2
   */
  @ViewChild('moduleLayout2', {
    read: ViewContainerRef,
    static: true
  })
  moduleLayout2;
  /**
   * ElementRef moduleLayout2
   */
  @ViewChild('moduleLayout_2', {
    read: ViewContainerRef,
    static: true
  })
  moduleLayout_2;

  /**
   * ElementRef resizer
   */
  @ViewChild('resizer', {
    read: ElementRef,
    static: true
  })
  resizer;
  /**
   * componentRef1
   */
  componentRef: ComponentRef<any>;
  /**
   * componentRef_1
   */
  componentRef_1: ComponentRef<any>;
  /**
   * componentRef2
   */
  componentRef2: ComponentRef<any>;
  /**
   * componentRef_2
   */
  componentRef_2: ComponentRef<any>;
  /**
   * ElementRef screen1
   */
  @ViewChild('screen1', {
    read: ElementRef,
    static: true
  })
  div1;
  /**
   * ElementRef screen2
   */
  @ViewChild('screen2', {
    read: ElementRef,
    static: true
  })
  div2;
  /**
   * true if mouse down, false if mouse up
   */
  isMouseDown = false;
  /**
   * true if mouse move resize1
   */
  isResize = false;
  /**
   * project's id
   */
  projectId: number;
  /**
   * true if zoom layout
   */
  isZoomLayout: boolean;
  /**
   * field component 2 is selected
   */
  fieldComponentSelected2;
  /**
   * common object
   */
  commonObject: Common;
  /**
   * Constructor
   * @param componentFactoryResolver ComponentFactoryResolver
   * @param changeDetectorRef ChangeDetectorRef
   * @param renderer Renderer2
   */
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    public readonly store: Store<AppState>,
    private dialogService: DialogService,
    private menuActionService: MenuActionService,
    private dataService: DataService,
    public translateService: TranslateService,
    private commonService: CommonService,
    private languageService: JhiLanguageService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {}

  /**
   * Catch the mouse down event
   * @param e event
   */
  @HostListener('mousedown', ['$event'])
  mouseDown(e) {
    switch (e.target.id) {
      // Case mouse down divResize1
      case 'editResizer1':
        this.isMouseDown = true;
        this.isResize = true;
        break;
      default:
        break;
    }
  }

  /**
   * Mouse move to resize layouts
   * @param e event
   */
  @HostListener('mousemove', ['$event'])
  mouseMove(e) {
    if (!this.isMouseDown) {
      return;
    }
    if (this.isResize) {
      Helper.resizeHeight(this.div1, this.div2, e, this.renderer);
    }
  }

  /**
   * Mouse up to unresize layouts
   * @param e event
   */
  @HostListener('mouseup', ['$event'])
  mouseUp(e) {
    if (this.isMouseDown) {
      this.isMouseDown = false;
      this.isResize = false;
      if (this.fieldComponentSelected2 && this.fieldComponentSelected2 == FIELD_COMPONENT.LcdLayoutEditorComponent) {
        this.dataService.sendData(['offsetTop', this.div2.nativeElement.offsetTop]);
      }
    }
  }

  /**
   * Update component
   */
  private updateComponent(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    if (this.componentRef_1) {
      this.componentRef_1.destroy();
    }
    if (this.componentRef_2) {
      this.componentRef_2.destroy();
    }
    if (this.componentRef2) {
      this.componentRef2.destroy();
    }

    this.colorTask = '#000';
    this.colorTask2 = '#000';

    this.commonObject = Helper.getCommonObject();
    if (Object.values(this.commonObject)?.every(object => !object)) {
      this.commonObject = this.commonService.getCommonObject();
    } else {
      sessionStorage.removeItem(Constant.COMMON_INFORMATION);
      this.store.dispatch(
        new SaveUserInfoAction({
          user: this.commonObject.user,
          privileges: this.commonObject.privileges
        })
      );
      this.dataService.sendData([Constant.UPDATE_VIEW_BY_PRIVILEGES, null]);
      this.store.dispatch(
        new SaveMainStateAction({
          common: this.commonObject
        })
      );
    }

    // handle when F5 or enter url /{language}
    let language = this.activatedRoute.snapshot.params.language;
    if (language && this.commonObject.setting?.language) {
      if (Helper.checkValidLanguageOnUrl(language)) {
        this.router.navigate(['']);
      } else {
        this.router.navigate([Constant.ERROR_404_PATH]);
      }
      return;
    }
    this.languageService.changeLanguage(this.commonObject.setting?.language);
    var interval = null;
    interval = setInterval(() => {
      if (this.commonObject.userName) {
        this.dataService.sendData(['userName', this.commonObject.userName]);
        clearInterval(interval);
      }
    }, 50);
    this.dataService.sendData(['projectName', this.commonObject.projectName]);
    this.resetComponent();
    this.openComponentByFunctionAndLayout(this.commonObject.fieldComponent1);
    this.openComponentByFunctionAndLayout(this.commonObject.fieldComponent2);
  }

  /**
   * open component by function and layout
   * @param fieldComponent
   */
  private openComponentByFunctionAndLayout(fieldComponent: string): void {
    if (!fieldComponent) {
      return;
    }
    let array1s = fieldComponent.split('-');
    this.openComponent(+array1s[0], +array1s[1], true);
  }

  /**
   * reset component
   */
  private resetComponent(): void {
    const resetComponents = Helper.openComponents(this.commonObject);
    resetComponents.forEach(component => {
      this.commonObject[component] = undefined;
    });
    this.store.dispatch(
      new SaveMainStateAction({
        common: this.commonObject
      })
    );
  }

  /**
   * return true if program is chosen
   * @param value
   * @param fieldComponent
   */
  public isChosenProgram(value: string, fieldComponent: any): boolean {
    if (this.isZoomLayout) {
      return false;
    }
    if (value == Constant.FIELD_COMPONENT_1) {
      let restore = this.commonService.getCommonObject().fieldComponent2?.split('-');
      return restore && fieldComponent == +restore[0];
    } else {
      let restore = this.commonService.getCommonObject().fieldComponent1?.split('-');
      return restore && fieldComponent == +restore[0];
    }
  }

  /**
   * open component
   * @param fieldComponent FIELD_COMPONENT
   * @param numberLayout NUMBER_LAYOUT
   * @param isNoResetStore
   */
  private openComponent(fieldComponent?: FIELD_COMPONENT, numberLayout?: NUMBER_LAYOUT, isNoResetStore?: boolean): void {
    if (numberLayout == NUMBER_LAYOUT.LAYOUT_1) {
      this.commonObject.fieldComponent1 = `${fieldComponent}-${numberLayout}`;
      this.store.dispatch(
        new SaveMainStateAction({
          common: this.commonObject
        })
      );
      this.moduleLayout.clear();
      this.moduleLayout_1.clear();
      this.moduleName = this.MODULE_NAME[fieldComponent == undefined ? '' : fieldComponent];
      this.colorTask = COLOR_TITLE[fieldComponent == undefined ? COLOR_TITLE.length - 1 : fieldComponent];
      if (this.componentRef) {
        this.componentRef.destroy();
      }
      if (this.componentRef_1) {
        this.componentRef_1.destroy();
      }
      if (fieldComponent == null) {
        return;
      }
      if (!isNoResetStore) {
        Helper.resetComponentState(fieldComponent, this.store);
      }
      let factory = this.componentFactoryResolver.resolveComponentFactory(this.entryComponents[fieldComponent]);
      let factoryMenu = this.componentFactoryResolver.resolveComponentFactory(menuComponents[fieldComponent]);
      this.componentRef = this.moduleLayout.createComponent(factory);
      this.componentRef_1 = this.moduleLayout_1.createComponent(factoryMenu);
      this.changeDetectorRef.detectChanges();
    } else {
      this.fieldComponentSelected2 = fieldComponent;
      this.commonObject.fieldComponent2 = `${fieldComponent}-${numberLayout}`;
      this.store.dispatch(
        new SaveMainStateAction({
          common: this.commonObject
        })
      );
      this.moduleLayout2.clear();
      this.moduleLayout_2.clear();
      this.moduleName2 = this.MODULE_NAME[fieldComponent == undefined ? '' : fieldComponent];
      this.colorTask2 = COLOR_TITLE[fieldComponent == undefined ? COLOR_TITLE.length - 1 : fieldComponent];
      if (this.componentRef2) {
        this.componentRef2.destroy();
      }
      if (this.componentRef_2) {
        this.componentRef_2.destroy();
      }
      if (fieldComponent == null) {
        return;
      }
      if (!isNoResetStore) {
        Helper.resetComponentState(fieldComponent, this.store);
      }
      let factory = this.componentFactoryResolver.resolveComponentFactory(this.entryComponents[fieldComponent]);
      let factoryMenu = this.componentFactoryResolver.resolveComponentFactory(menuComponents[fieldComponent]);
      this.componentRef2 = this.moduleLayout2.createComponent(factory);
      this.componentRef_2 = this.moduleLayout_2.createComponent(factoryMenu);
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        if (fieldComponent == FIELD_COMPONENT.LcdLayoutEditorComponent) {
          this.dataService.sendData(['offsetTop', this.div2.nativeElement.offsetTop]);
        }
      });
    }
  }

  /**
   * change component
   * @param fieldComponent
   * @param numberLayout
   * @param isNoResetStore
   */
  public changeComponent(fieldComponent?: any, numberLayout?: NUMBER_LAYOUT, isNoResetStore?: boolean): void {
    const result =
      numberLayout == NUMBER_LAYOUT.LAYOUT_1
        ? Helper.isChangeDataBeforeLeave(this.componentRef)
        : Helper.isChangeDataBeforeLeave(this.componentRef2);
    if (result) {
      this.confirmSave(fieldComponent, numberLayout, isNoResetStore, this.componentRef);
    } else {
      this.openComponent(fieldComponent, numberLayout, isNoResetStore);
    }
  }

  /**
   * confirm save
   * @param fieldComponent
   * @param numberLayout
   * @param isNoResetStore
   */
  private confirmSave(
    fieldComponent?: FIELD_COMPONENT,
    numberLayout?: NUMBER_LAYOUT,
    isNoResetStore?: boolean,
    componentRef?: ComponentRef<any>
  ): void {
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text:
            this.moduleName == this.MODULE_NAME[23] && componentRef?.instance?.isEditText
              ? this.translateService.instant('schedule-operation-manager.tab-notification-registration.msg.confirm-save-change')
              : this.translateService.instant('layout.confirm-save'),
          button1: this.translateService.instant('layout.yes'),
          button2: this.translateService.instant('layout.no')
        }
      },
      result => {
        if (result) {
          if (numberLayout == NUMBER_LAYOUT.LAYOUT_1) {
            this.menuActionService.save(this.moduleName);
            this.saveDataForLayout(this.componentRef, fieldComponent, numberLayout, isNoResetStore);
          } else {
            this.menuActionService.save(this.moduleName2);
            this.saveDataForLayout(this.componentRef2, fieldComponent, numberLayout, isNoResetStore);
          }
        } else {
          this.openComponent(fieldComponent, numberLayout, isNoResetStore);
        }
      }
    );
  }

  /**
   * save data for layout
   * @param componentRef
   * @param fieldComponent
   * @param numberLayout
   * @param isNoResetStore
   */
  private saveDataForLayout(componentRef, fieldComponent?: FIELD_COMPONENT, numberLayout?: NUMBER_LAYOUT, isNoResetStore?: boolean): void {
    const sub = componentRef.instance.saveDataSuccess.subscribe(isSuccess => {
      sub.unsubscribe();
      if (isSuccess) {
        this.openComponent(fieldComponent, numberLayout, isNoResetStore);
      }
    });
  }

  /**
   * ngOnChanges
   */
  ngOnChanges() {
    this.updateComponent();
  }

  /**
   * ngAfterViewInit
   */
  ngAfterViewInit() {
    this.updateComponent();
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    if (this.componentRef_1) {
      this.componentRef_1.destroy();
    }
    if (this.componentRef_2) {
      this.componentRef_2.destroy();
    }
    if (this.componentRef2) {
      this.componentRef2.destroy();
    }
  }

  /**
   * handle zooming screen
   * @param layout NUMBER_LAYOUT
   */
  public zoomOutScreen(layout: NUMBER_LAYOUT): void {
    this.isZoomLayout = true;
    if (layout == NUMBER_LAYOUT.LAYOUT_2) {
      this.commonObject.fieldComponent1 = `${this.fieldComponentSelected2}-${NUMBER_LAYOUT.LAYOUT_1}`;
    }
    this.commonObject.fieldComponent2 = undefined;
    this.dataService.sendData(['zoomOutScreen', true]);
  }

  /**
   * choose program
   */
  public chooseProgram(screen: HTMLDivElement, menuSoftware: HTMLDivElement): void {
    this.projectId = +this.commonObject.projectId;
    Helper.handleScrollMenu(screen, menuSoftware);
  }

  /**
   * Resized Event
   */
  public onResized(): void {
    Helper.handleScrollMenu(this.screen1.nativeElement, this.menuSoftware1.nativeElement);
    Helper.handleScrollMenu(this.screen2.nativeElement, this.menuSoftware2.nativeElement);
  }
}

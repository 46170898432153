import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  /**
   * errorMessage
   */
  errorMessage?: string;
  /**
   * constants
   */
  readonly ERROR_PAGE = 'Error page!';
  readonly CANVAS_WIDTH = 1920;
  readonly CANVAS_HEIGHT = 969;

  @ViewChild('canvasError', { static: false })
  canvasError: ElementRef;
  /**
   * busImg
   */
  busImg = new Image();
  /**
   * cloud1Img
   */
  cloud1Img = new Image();
  /**
   * cloud2Img
   */
  cloud2Img = new Image();
  /**
   * sunImg
   */
  sunImg = new Image();
  /**
   * roadImg
   */
  roadImg = new Image();
  /**
   * cloud1X
   */
  cloud1X = 180;
  /**
   * cloud2X
   */
  cloud2X = 10;
  /**
   * roadX
   */
  roadX = -549;
  /**
   * msgcloud1X
   */
  msgcloud1X = 250;
  /**
   * msgcloud2X
   */
  msgcloud2X = 85;
  /**
   * rotation
   */
  rotation = 0;
  /**
   * canvas
   */
  canvas: any;
  /**
   * ctx
   */
  ctx: any;
  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.busImg.src = 'content/images/bus.svg';
    this.cloud1Img.src = 'content/images/cloud1.svg';
    this.cloud2Img.src = 'content/images/cloud2.svg';
    this.roadImg.src = 'content/images/road.svg';
    this.sunImg.src = 'content/images/sun.svg';
    this.route.data.subscribe(routeData => {
      if (routeData.errorMessage) {
        this.errorMessage = routeData.errorMessage;
      }
    });
    this.titleService.setTitle(this.ERROR_PAGE);
    this.changeDetectorRef.detectChanges();
    this.createCanvas(this.renderer);
    setInterval(() => {
      this.drawText404();
    }, 0);
  }

  /**
   * create canvas
   * @param canvasContainer ElementRef
   * @param renderer
   */
  public createCanvas(renderer: Renderer2) {
    this.canvas = renderer.createElement('canvas');
    this.canvas.style.position = 'absolute';
    this.canvas.style.background = '#fff';
    this.canvas.style.width = this.CANVAS_WIDTH + 'px';
    this.canvas.style.height = this.CANVAS_HEIGHT + 'px';
    this.canvas.width = this.CANVAS_WIDTH;
    this.canvas.height = this.CANVAS_HEIGHT;
    renderer.appendChild(this.canvasError?.nativeElement, this.canvas);
  }

  /**
   * draw bus img
   */
  drawBusImg() {
    this.ctx = this.canvas.getContext('2d');
    this.ctx.drawImage(this.busImg, 1000, 550, 850, 300);
  }

  /**
   * draw cloud
   */
  drawCloudImg() {
    this.ctx = this.canvas.getContext('2d');
    this.ctx.drawImage(this.cloud1Img, this.cloud1X, 40, this.cloud1Img.width, this.cloud1Img.height);
    this.ctx.drawImage(this.cloud1Img, this.cloud2X, 200, this.cloud1Img.width, this.cloud1Img.height);
    this.cloud1X += 0.2;
    this.cloud2X += 0.1;
    if (this.cloud1X > 1930) {
      this.cloud1X = -250;
    }
    if (this.cloud2X > 1930) {
      this.cloud2X = -250;
    }
  }

  /**
   * draw car wheel
   */
  drawWheelImg() {
    this.rotation -= 5;
    this.draw(1191, 800, this.rotation, 'white');
    this.draw(1616, 800, this.rotation, 'white');
  }

  /**
   * draw road
   */
  drawRoadImg() {
    this.ctx = this.canvas.getContext('2d');
    this.ctx.drawImage(this.roadImg, this.roadX, 865, 2495, 20);
    this.roadX += 1;
    if (this.roadX > 0) {
      this.roadX = -554;
    }
  }

  /**
   * draw sun wheel
   */
  drawSunImg() {
    this.rotation += 1;
    this.drawSunWheel(this.sunImg, this.rotation, 200, 200);
  }

  /**
   * draw and control sun wheel
   * @param {*} img
   * @param {*} degrees
   * @param {*} w
   * @param {*} h
   */
  drawSunWheel(img, degrees, w, h) {
    this.ctx = this.canvas.getContext('2d');
    var radians = degrees / (Math.PI * 180);
    this.ctx.save();
    this.ctx.translate(100, 100);
    this.ctx.beginPath();
    this.ctx.rotate(radians);
    this.ctx.drawImage(img, -w / 2, -h / 2, w, h);
    this.ctx.stroke();
    this.ctx.restore();
  }

  /**
   * draw circle
   * @param {*} x
   * @param {*} y
   * @param {*} degrees
   * @param {*} color
   */
  draw(x, y, degrees, color) {
    this.ctx = this.canvas.getContext('2d');
    var radians = degrees * (Math.PI / 180);
    this.ctx.save();
    this.ctx.beginPath();
    this.ctx.translate(x, y);
    this.ctx.rotate(radians / 2);
    this.ctx.fillStyle = 'black';
    this.ctx.strokeStyle = 'white';
    this.ctx.arc(0, 0, 53, 0, 2 * Math.PI, false);
    this.ctx.fill();
    this.ctx.stroke();
    this.ctx.beginPath();
    this.ctx.strokeStyle = color;
    this.ctx.lineWidth = 20;
    this.ctx.moveTo(-10, 0);
    this.ctx.lineTo(+10, 0);
    this.ctx.stroke();
    this.ctx.restore();
  }

  /**
   * draw text 404
   */
  drawText404() {
    this.ctx = this.canvas.getContext('2d');
    this.ctx.clearRect(0, 0, this.CANVAS_WIDTH, this.CANVAS_HEIGHT);
    this.drawBusImg();
    this.drawSunImg();
    this.drawRoadImg();
    this.drawWheelImg();
    this.drawText();
    this.drawCloudImg();
    this.ctx.font = '100px serif';
    this.ctx.fillStyle = 'black';
    this.ctx.fillText('404', this.msgcloud1X, 165);
    this.ctx.fillText('404', this.msgcloud2X, 320);
    this.msgcloud1X += 0.2;
    this.msgcloud2X += 0.1;
    if (this.msgcloud1X > 1930) {
      this.msgcloud1X = -250;
    }
    if (this.msgcloud2X > 1930) {
      this.msgcloud2X = -250;
    }
  }

  /**
   * draw text
   */
  drawText() {
    this.ctx = this.canvas.getContext('2d');
    this.ctx.font = '65px serif';
    this.ctx.fillStyle = 'white';
    this.ctx.fillText('Page not found.', 1195, 735);
  }
}

import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  ALL_SOFTWARES,
  COLOR_TITLE,
  Constant,
  FIELD_COMPONENT,
  MODULE_NAME,
  MODULE_NAME_NEED_PROJECT,
  NUMBER_LAYOUT,
  TYPE_LAYOUT,
  menuComponents
} from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { Common } from 'app/model/entity/common';
import { AnnouncementManagerComponent } from 'app/module/announcement-manager/announcement-manager.component';
import { BusInformationDisplayEditorComponent } from 'app/module/bus-information-display-editor/bus-information-display-editor.component';
import { DashboardComponent } from 'app/module/dashboard/dashboard.component';
import { DeliveryManagerComponent } from 'app/module/delivery-manager/delivery-manager.component';
import { DestinationSignEditorComponent } from 'app/module/destination-sign-editor/destination-sign-editor.component';
import { DeviceManagerComponent } from 'app/module/device-manager/device-manager.component';
import { DigitalSignageContentEditorComponent } from 'app/module/digital-signage-content-editor/digital-signage-content-editor.component';
import { EdsEditorComponent } from 'app/module/eds-editor/eds-editor.component';
import { ExternalContentManagerComponent } from 'app/module/external-content-manager/external-content-manager.component';
import { IndexWordEditorComponent } from 'app/module/index-word-editor/index-word-editor.component';
import { LcdLayoutEditorComponent } from 'app/module/lcd-layout-editor/lcd-layout-editor.component';
import { LedLayoutEditorComponent } from 'app/module/led-layout-editor/led-layout-editor.component';
import { MasterListEditorComponent } from 'app/module/master-list-editor/master-list-editor.component';
import { MediaManagerComponent } from 'app/module/media-manager/media-manager.component';
import { ProjectManagerComponent } from 'app/module/project-manager/project-manager.component';
import { RouteListEditorComponent } from 'app/module/route-list-editor/route-list-editor.component';
import { ScheduleMergeComponent } from 'app/module/schedule-merge/schedule-merge.component';
import { ScheduleOperationManagerComponent } from 'app/module/schedule-operation-manager/schedule-operation-manager.component';
import { SignageDisplayEditorComponent } from 'app/module/signage-display-editor/signage-display-editor.component';
import { SimpleSignageEditorComponent } from 'app/module/simple-signage-editor/simple-signage-editor.component';
import { StationDisplayEditorComponent } from 'app/module/station-display-editor/station-display-editor.component';
import { TicketEditorTabSpotComponent } from 'app/module/ticket-editor/ticket-editor-tab-spot/ticket-editor-tab-spot.component';
import { TicketEditorComponent } from 'app/module/ticket-editor/ticket-editor.component';
import { TicketManagerComponent } from 'app/module/ticket-manager/ticket-manager.component';
import { TimetableEditorComponent } from 'app/module/timetable-editor/timetable-editor.component';
import { TimetableOperationManagerComponent } from 'app/module/timetable-operation-manager/timetable-operation-manager.component';
import { UserManagerComponent } from 'app/module/user-manager/user-manager.component';
import { SaveMainStateAction, SaveUserInfoAction } from 'app/ngrx-component-state-management/component-state.action';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { LoginModalComponent } from 'app/shared/login/login.component';
import { AppState } from 'app/store/app.state';
import { JhiLanguageService } from 'ng-jhipster';
import { Helper } from '../helper';
import { ScheduleRegistrationComponent } from './../../module/schedule-registration/schedule-registration.component';

@Component({
  selector: 'app-layout6',
  templateUrl: './layout6.component.html',
  styleUrls: ['./layout6.component.scss']
})
export class Layout6Component implements OnInit {
  /**
   * entryComponents
   */
  entryComponents = [
    UserManagerComponent,
    ProjectManagerComponent,
    DeviceManagerComponent,
    IndexWordEditorComponent,
    ExternalContentManagerComponent,
    LcdLayoutEditorComponent,
    LedLayoutEditorComponent,
    MasterListEditorComponent,
    RouteListEditorComponent,
    TimetableEditorComponent,
    DestinationSignEditorComponent,
    SignageDisplayEditorComponent,
    BusInformationDisplayEditorComponent,
    StationDisplayEditorComponent,
    ScheduleRegistrationComponent,
    ScheduleMergeComponent,
    DeliveryManagerComponent,
    TicketEditorComponent,
    TicketEditorTabSpotComponent,
    MediaManagerComponent,
    DigitalSignageContentEditorComponent,
    SimpleSignageEditorComponent,
    TimetableOperationManagerComponent,
    ScheduleOperationManagerComponent,
    AnnouncementManagerComponent,
    TicketManagerComponent,
    DashboardComponent,
    LoginModalComponent,
    EdsEditorComponent
  ];
  @ViewChild('screen1') private screen1: ElementRef;
  @ViewChild('screen2') private screen2: ElementRef;
  @ViewChild('screen3') private screen3: ElementRef;
  @ViewChild('menuSoftware1') private menuSoftware1: ElementRef;
  @ViewChild('menuSoftware2') private menuSoftware2: ElementRef;
  @ViewChild('menuSoftware3') private menuSoftware3: ElementRef;

  menuComponents = menuComponents;
  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;
  /**
   * TYPE_LAYOUT
   */
  TYPE_LAYOUT = TYPE_LAYOUT;
  /**
   * NUMBER_LAYOUT
   */
  NUMBER_LAYOUT = NUMBER_LAYOUT;
  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;
  /**
   * COLOR_TITLE
   */
  COLOR_TITLE = COLOR_TITLE;
  /**
   * ALL_SOFTWARES
   */
  ALL_SOFTWARES = ALL_SOFTWARES;
  MODULE_NAME_NEED_PROJECT = MODULE_NAME_NEED_PROJECT;
  /**
   * typeLayout
   */
  typeLayout: TYPE_LAYOUT;

  /**
   * moduleName of layout 1
   */
  moduleName: string;
  /**
   * moduleName of layout 2
   */
  moduleName2: string;
  /**
   * moduleName of layout 3
   */
  moduleName3: string;
  /**
   * colorTask of layout 1
   */
  colorTask: string;
  /**
   * colorTask of layout 2
   */
  colorTask2: string;
  /**
   * colorTask of layout 3
   */
  colorTask3: string;

  /**
   * ElementRef moduleLayout1
   */
  @ViewChild('moduleLayout', {
    read: ViewContainerRef,
    static: true
  })
  moduleLayout;
  /**
   * ElementRef moduleLayout2
   */
  @ViewChild('moduleLayout2', {
    read: ViewContainerRef,
    static: true
  })
  moduleLayout2;
  /**
   * ElementRef moduleLayout2
   */
  @ViewChild('moduleLayout3', {
    read: ViewContainerRef,
    static: true
  })
  moduleLayout3;

  @ViewChild('moduleLayout_1', {
    read: ViewContainerRef,
    static: true
  })
  moduleLayout_1;

  @ViewChild('moduleLayout_2', {
    read: ViewContainerRef,
    static: true
  })
  moduleLayout_2;
  /**
   * ElementRef moduleLayout2
   */
  @ViewChild('moduleLayout_3', {
    read: ViewContainerRef,
    static: true
  })
  moduleLayout_3;

  /**
   * ElementRef screen1
   */
  @ViewChild('screen1', {
    read: ElementRef,
    static: true
  })
  div1;
  /**
   * ElementRef screen2
   */
  @ViewChild('screen2', {
    read: ElementRef,
    static: true
  })
  div2;
  /**
   * ElementRef resizer
   */
  @ViewChild('resizer', {
    read: ElementRef,
    static: true
  })
  resizer;
  /**
   * ElementRef editResizer1
   */
  @ViewChild('editResizer1', {
    read: ElementRef,
    static: true
  })
  editResizer1;
  /**
   * componentRef1
   */
  componentRef: ComponentRef<any>;
  /**
   * componentRef2
   */
  componentRef2: ComponentRef<any>;
  /**
   * componentRef3
   */
  componentRef3: ComponentRef<any>;
  /**
   * componentRef1
   */
  componentRef_1: ComponentRef<any>;
  /**
   * componentRef2
   */
  componentRef_2: ComponentRef<any>;
  /**
   * componentRef3
   */
  componentRef_3: ComponentRef<any>;
  /**
   * ElementRef sectionLayout1
   */
  @ViewChild('sectionLayout', {
    read: ElementRef,
    static: true
  })
  sectionLayout;
  /**
   * ElementRef sectionLayout2
   */
  @ViewChild('sectionLayout2', {
    read: ElementRef,
    static: true
  })
  sectionLayout2;
  /**
   * true if mouse down, false if mouse up
   */
  isMouseDown = false;
  /**
   * true if mouse move resize1
   */
  isResize = false;
  /**
   * true if mouse move editResize1
   */
  isEditResize1 = false;
  /**
   * project's id
   */
  projectId: number;
  /**
   * true if zoom layout
   */
  isZoomLayout: boolean;
  /**
   * field component 2 is selected
   */
  fieldComponentSelected2;
  /**
   * field component 3 is selected
   */
  fieldComponentSelected3;
  /**
   * common object
   */
  commonObject: Common;
  /**
   * Constructor
   * @param componentFactoryResolver ComponentFactoryResolver
   * @param changeDetectorRef ChangeDetectorRef
   * @param renderer Renderer2
   */
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    public readonly store: Store<AppState>,
    private dialogService: DialogService,
    private menuActionService: MenuActionService,
    private dataService: DataService,
    public translateService: TranslateService,
    private commonService: CommonService,
    private languageService: JhiLanguageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.scrollTheScreen();
  }
  /**
   * Catch the mouse down event
   * @param e event
   */
  @HostListener('mousedown', ['$event'])
  mouseDown(e) {
    switch (e.target.id) {
      // Case mouse down divEditResize1
      case 'divEditResize1':
        this.isMouseDown = true;
        this.isEditResize1 = true;
        break;
      // Case mouse down divResize1
      case 'divResize1':
        this.isMouseDown = true;
        this.isResize = true;
        break;
      default:
        break;
    }
  }

  /**
   * Mouse move to resize layouts
   * @param e event
   */
  @HostListener('mousemove', ['$event'])
  mouseMove(e) {
    if (!this.isMouseDown) {
      return;
    }
    if (this.isEditResize1) {
      Helper.resizeWidth(this.sectionLayout, this.sectionLayout2, e, this.renderer);
      this.handlingDisplayMenuLayout();
    }

    if (this.isResize) {
      Helper.resizeHeight(this.div1, this.div2, e, this.renderer);
    }
  }

  /**
   * Mouse up to unresize layouts
   * @param e event
   */
  @HostListener('mouseup', ['$event'])
  mouseUp(e) {
    if (this.isMouseDown) {
      this.isMouseDown = false;
      if (this.isEditResize1) {
        if (this.fieldComponentSelected3 == FIELD_COMPONENT.LcdLayoutEditorComponent) {
          this.dataService.sendData(['offsetLeft', this.sectionLayout2.nativeElement.offsetLeft]);
        }
      }
      this.isEditResize1 = false;
      if (this.isResize) {
        if (this.fieldComponentSelected2 == FIELD_COMPONENT.LcdLayoutEditorComponent) {
          this.dataService.sendData(['offsetTop', this.div2.nativeElement.offsetTop + 36]);
        }
      }
      this.isResize = false;
    }
  }

  /**
   * Update component
   */
  private updateComponent(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    if (this.componentRef2) {
      this.componentRef2.destroy();
    }
    if (this.componentRef3) {
      this.componentRef3.destroy();
    }
    if (this.componentRef_1) {
      this.componentRef_1.destroy();
    }
    if (this.componentRef_2) {
      this.componentRef_2.destroy();
    }
    if (this.componentRef_3) {
      this.componentRef_3.destroy();
    }

    this.colorTask = '#000';
    this.colorTask2 = '#000';
    this.colorTask3 = '#000';

    this.commonObject = Helper.getCommonObject();
    if (Object.values(this.commonObject)?.every(object => !object)) {
      this.commonObject = this.commonService.getCommonObject();
    } else {
      sessionStorage.removeItem(Constant.COMMON_INFORMATION);
      this.store.dispatch(
        new SaveUserInfoAction({
          user: this.commonObject.user,
          privileges: this.commonObject.privileges
        })
      );
      this.dataService.sendData([Constant.UPDATE_VIEW_BY_PRIVILEGES, null]);
      this.store.dispatch(
        new SaveMainStateAction({
          common: this.commonObject
        })
      );
    }

    // handle when F5 or enter url /{language}
    let language = this.activatedRoute.snapshot.params.language;
    if (language && this.commonObject.setting?.language) {
      if (Helper.checkValidLanguageOnUrl(language)) {
        this.router.navigate(['']);
      } else {
        this.router.navigate([Constant.ERROR_404_PATH]);
      }
      return;
    }
    this.languageService.changeLanguage(this.commonObject.setting?.language);
    var interval = null;
    interval = setInterval(() => {
      if (this.commonObject.userName) {
        this.dataService.sendData(['userName', this.commonObject.userName]);
        clearInterval(interval);
      }
    }, 50);
    this.dataService.sendData(['projectName', this.commonObject.projectName]);
    this.resetComponent();
    this.openComponentByFunctionAndLayout(this.commonObject.fieldComponent1);
    this.openComponentByFunctionAndLayout(this.commonObject.fieldComponent2);
    this.openComponentByFunctionAndLayout(this.commonObject.fieldComponent3);
  }

  /**
   * open component by function and layout
   * @param fieldComponent
   */
  private openComponentByFunctionAndLayout(fieldComponent: string): void {
    if (!fieldComponent) {
      return;
    }
    let array1s = fieldComponent.split('-');
    this.openComponent(+array1s[0], +array1s[1], true);
  }

  /**
   * reset component
   */
  private resetComponent(): void {
    const resetComponents = Helper.openComponents(this.commonObject);
    resetComponents.forEach(component => {
      this.commonObject[component] = undefined;
    });
    this.store.dispatch(
      new SaveMainStateAction({
        common: this.commonObject
      })
    );
  }

  /**
   * return true if program is chosen
   * @param value
   * @param fieldComponent
   */
  public isChosenProgram(value: string, fieldComponent: any): boolean {
    if (this.isZoomLayout) {
      return false;
    }
    if (value == Constant.FIELD_COMPONENT_1) {
      let restore2 = this.commonService.getCommonObject().fieldComponent2?.split('-');
      let restore3 = this.commonService.getCommonObject().fieldComponent3?.split('-');
      return (restore2 && fieldComponent == +restore2[0]) || (restore3 && fieldComponent == +restore3[0]);
    } else if (value == Constant.FIELD_COMPONENT_2) {
      let restore = this.commonService.getCommonObject().fieldComponent1?.split('-');
      let restore3 = this.commonService.getCommonObject().fieldComponent3?.split('-');
      return (restore && fieldComponent == +restore[0]) || (restore3 && fieldComponent == +restore3[0]);
    } else {
      let restore = this.commonService.getCommonObject().fieldComponent1?.split('-');
      let restore2 = this.commonService.getCommonObject().fieldComponent2?.split('-');
      return (restore && fieldComponent == +restore[0]) || (restore2 && fieldComponent == +restore2[0]);
    }
  }

  /**
   * open component
   * @param fieldComponent FIELD_COMPONENT
   * @param numberLayout NUMBER_LAYOUT
   * @param isNoResetStore
   */
  private openComponent(fieldComponent?: FIELD_COMPONENT, numberLayout?: NUMBER_LAYOUT, isNoResetStore?: boolean): void {
    if (numberLayout == NUMBER_LAYOUT.LAYOUT_1) {
      this.commonObject.fieldComponent1 = `${fieldComponent}-${numberLayout}`;
      this.store.dispatch(
        new SaveMainStateAction({
          common: this.commonObject
        })
      );
      this.moduleLayout.clear();
      this.moduleLayout_1.clear();
      this.moduleName = this.MODULE_NAME[fieldComponent == undefined ? '' : fieldComponent];
      this.colorTask = COLOR_TITLE[fieldComponent == undefined ? COLOR_TITLE.length - 1 : fieldComponent];
      if (this.componentRef) {
        this.componentRef.destroy();
      }
      if (this.componentRef_1) {
        this.componentRef_1.destroy();
      }
      if (fieldComponent == null) {
        return;
      }
      if (!isNoResetStore) {
        Helper.resetComponentState(fieldComponent, this.store);
      }
      let factory = this.componentFactoryResolver.resolveComponentFactory(this.entryComponents[fieldComponent]);
      let factoryMenu = this.componentFactoryResolver.resolveComponentFactory(menuComponents[fieldComponent]);
      this.componentRef = this.moduleLayout.createComponent(factory);
      this.componentRef_1 = this.moduleLayout_1.createComponent(factoryMenu);
      this.changeDetectorRef.detectChanges();
    } else if (numberLayout == NUMBER_LAYOUT.LAYOUT_2) {
      this.fieldComponentSelected2 = fieldComponent;
      this.commonObject.fieldComponent2 = `${fieldComponent}-${numberLayout}`;
      this.store.dispatch(
        new SaveMainStateAction({
          common: this.commonObject
        })
      );
      this.moduleLayout2.clear();
      this.moduleLayout_2.clear();
      this.moduleName2 = this.MODULE_NAME[fieldComponent == undefined ? '' : fieldComponent];
      this.colorTask2 = COLOR_TITLE[fieldComponent == undefined ? COLOR_TITLE.length - 1 : fieldComponent];
      if (this.componentRef2) {
        this.componentRef2.destroy();
      }
      if (this.componentRef_2) {
        this.componentRef_2.destroy();
      }
      if (fieldComponent == null) {
        return;
      }
      if (!isNoResetStore) {
        Helper.resetComponentState(fieldComponent, this.store);
      }
      let factory = this.componentFactoryResolver.resolveComponentFactory(this.entryComponents[fieldComponent]);
      let factoryMenu = this.componentFactoryResolver.resolveComponentFactory(menuComponents[fieldComponent]);
      this.componentRef2 = this.moduleLayout2.createComponent(factory);
      this.componentRef_2 = this.moduleLayout_2.createComponent(factoryMenu);
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        if (fieldComponent == FIELD_COMPONENT.LcdLayoutEditorComponent) {
          this.dataService.sendData(['offsetTop', this.div2.nativeElement.offsetTop + 36]);
        }
      });
    } else {
      this.fieldComponentSelected3 = fieldComponent;
      this.commonObject.fieldComponent3 = `${fieldComponent}-${numberLayout}`;
      this.store.dispatch(
        new SaveMainStateAction({
          common: this.commonObject
        })
      );
      this.moduleLayout3.clear();
      this.moduleLayout_3.clear();
      this.moduleName3 = this.MODULE_NAME[fieldComponent == undefined ? '' : fieldComponent];
      this.colorTask3 = COLOR_TITLE[fieldComponent == undefined ? COLOR_TITLE.length - 1 : fieldComponent];
      if (this.componentRef3) {
        this.componentRef3.destroy();
      }
      if (this.componentRef_3) {
        this.componentRef_3.destroy();
      }
      if (fieldComponent == null) {
        return;
      }
      if (!isNoResetStore) {
        Helper.resetComponentState(fieldComponent, this.store);
      }
      let factory = this.componentFactoryResolver.resolveComponentFactory(this.entryComponents[fieldComponent]);
      let factoryMenu = this.componentFactoryResolver.resolveComponentFactory(menuComponents[fieldComponent]);
      this.componentRef3 = this.moduleLayout3.createComponent(factory);
      this.componentRef_3 = this.moduleLayout_3.createComponent(factoryMenu);
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        if (fieldComponent == FIELD_COMPONENT.LcdLayoutEditorComponent) {
          this.dataService.sendData(['offsetLeft', this.sectionLayout2.nativeElement.offsetLeft]);
        }
      });
    }
  }

  public changeComponent(fieldComponent?: any, numberLayout?: NUMBER_LAYOUT, isNoResetStore?: boolean): void {
    const result =
      numberLayout == NUMBER_LAYOUT.LAYOUT_1
        ? Helper.isChangeDataBeforeLeave(this.componentRef)
        : numberLayout == NUMBER_LAYOUT.LAYOUT_2
        ? Helper.isChangeDataBeforeLeave(this.componentRef2)
        : Helper.isChangeDataBeforeLeave(this.componentRef3);
    if (result) {
      this.confirmSave(fieldComponent, numberLayout, isNoResetStore, this.componentRef);
    } else {
      this.openComponent(fieldComponent, numberLayout, isNoResetStore);
    }
    this.handlingDisplayMenuLayout();
  }

  /**
   * confirm save
   * @param fieldComponent
   * @param numberLayout
   * @param isNoResetStore
   */
  private confirmSave(
    fieldComponent?: FIELD_COMPONENT,
    numberLayout?: NUMBER_LAYOUT,
    isNoResetStore?: boolean,
    componentRef?: ComponentRef<any>
  ): void {
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text:
            this.moduleName == this.MODULE_NAME[23] && componentRef?.instance?.isEditText
              ? this.translateService.instant('schedule-operation-manager.tab-notification-registration.msg.confirm-save-change')
              : this.translateService.instant('layout.confirm-save'),
          button1: this.translateService.instant('layout.yes'),
          button2: this.translateService.instant('layout.no')
        }
      },
      result => {
        if (result) {
          if (numberLayout == NUMBER_LAYOUT.LAYOUT_1) {
            this.menuActionService.save(this.moduleName);
            this.saveDataForLayout(this.componentRef, fieldComponent, numberLayout, isNoResetStore);
          } else if (numberLayout == NUMBER_LAYOUT.LAYOUT_2) {
            this.menuActionService.save(this.moduleName2);
            this.saveDataForLayout(this.componentRef2, fieldComponent, numberLayout, isNoResetStore);
          } else {
            this.menuActionService.save(this.moduleName3);
            this.saveDataForLayout(this.componentRef3, fieldComponent, numberLayout, isNoResetStore);
          }
        } else {
          this.openComponent(fieldComponent, numberLayout, isNoResetStore);
        }
      }
    );
  }

  /**
   * save data for layout
   * @param componentRef
   * @param fieldComponent
   * @param numberLayout
   * @param isNoResetStore
   */
  private saveDataForLayout(componentRef, fieldComponent?: FIELD_COMPONENT, numberLayout?: NUMBER_LAYOUT, isNoResetStore?: boolean): void {
    const sub = componentRef.instance.saveDataSuccess.subscribe(isSuccess => {
      sub.unsubscribe();
      if (isSuccess) {
        this.openComponent(fieldComponent, numberLayout, isNoResetStore);
      }
    });
  }

  /**
   * ngOnChanges
   */
  ngOnChanges() {
    this.updateComponent();
  }

  /**
   * ngAfterViewInit
   */
  ngAfterViewInit() {
    this.updateComponent();
    this.handlingDisplayMenuLayout();
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    if (this.componentRef2) {
      this.componentRef2.destroy();
    }
    if (this.componentRef3) {
      this.componentRef3.destroy();
    }
    if (this.componentRef_1) {
      this.componentRef_1.destroy();
    }
    if (this.componentRef_2) {
      this.componentRef_2.destroy();
    }
    if (this.componentRef_3) {
      this.componentRef_3.destroy();
    }
  }

  /**
   * handle zooming screen
   * @param layout NUMBER_LAYOUT
   */
  public zoomOutScreen(layout: NUMBER_LAYOUT): void {
    this.isZoomLayout = true;
    switch (layout) {
      case NUMBER_LAYOUT.LAYOUT_1:
        Helper.handlingDisplayMenu(this.sectionLayout.nativeElement.offsetWidth, this.dataService, this.moduleName);
        break;
      case NUMBER_LAYOUT.LAYOUT_2:
        Helper.handlingDisplayMenu(this.sectionLayout.nativeElement.offsetWidth, this.dataService, this.moduleName2);
        this.commonObject.fieldComponent1 = `${this.fieldComponentSelected2}-${NUMBER_LAYOUT.LAYOUT_1}`;
        break;
      case NUMBER_LAYOUT.LAYOUT_3:
        Helper.handlingDisplayMenu(this.sectionLayout2.nativeElement.offsetWidth, this.dataService, this.moduleName3);
        this.commonObject.fieldComponent1 = `${this.fieldComponentSelected3}-${NUMBER_LAYOUT.LAYOUT_1}`;
        break;
      default:
        break;
    }
    this.commonObject.fieldComponent2 = undefined;
    this.commonObject.fieldComponent3 = undefined;
    this.dataService.sendData(['zoomOutScreen', true]);
  }

  /**
   * choose program
   */
  public chooseProgram(screen: HTMLDivElement, menuSoftware: HTMLDivElement): void {
    this.projectId = +this.commonObject.projectId;
    Helper.handleScrollMenu(screen, menuSoftware);
  }

  /**
   * Resized Event
   */
  public onResized(): void {
    Helper.handleScrollMenu(this.screen1.nativeElement, this.menuSoftware1.nativeElement);
    Helper.handleScrollMenu(this.screen2.nativeElement, this.menuSoftware2.nativeElement);
    Helper.handleScrollMenu(this.screen3.nativeElement, this.menuSoftware3.nativeElement);
    this.handlingDisplayMenuLayout();
    this.scrollTheScreen();
  }

  /**
   * handle  menu  when zoom in and out screen
   */
  private handlingDisplayMenuLayout(): void {
    Helper.handlingDisplayMenu(this.sectionLayout.nativeElement.offsetWidth, this.dataService, this.moduleName);
    Helper.handlingDisplayMenu(this.sectionLayout.nativeElement.offsetWidth, this.dataService, this.moduleName2);
    Helper.handlingDisplayMenu(this.sectionLayout2.nativeElement.offsetWidth, this.dataService, this.moduleName3);
  }

  /**
   * scrollTheScreen
   */
  scrollTheScreen(): void {
    const screenWidth = window.innerWidth;
    const zoomLevel = screenWidth / screen.availWidth;
    const sectionElement = this.el.nativeElement.querySelector('.cssScroll6');
    if (sectionElement.classList.contains('overflow-y-hidden')) {
      if (zoomLevel < 1) {
        this.renderer.setStyle(sectionElement, 'overflow-y', 'auto');
      } else if (zoomLevel >= 1) {
        this.renderer.setStyle(sectionElement, 'overflow-y', 'hidden');
      }
    }
  }
}
